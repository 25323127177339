<!--学生管理-->
<template>
    <div>
        <a-row class="searchBox">
            <a-space class="flex flex-wrap">
                <a-input-search v-model:value="param.search" placeholder="输入ID/学生姓名" style="width: 320px;" enter-button="搜索" @search="filterList" />
            </a-space>
        </a-row>

        <a-row class="tableBox">
            <a-table :dataSource="list" :columns="columns" rowKey="id" :pagination="false" :scroll="{x: 1000, y: 'calc(100vh - 390px)' }">
                <template #number="{text, record, index}">
                    <span>{{ (param.curr - 1) * param.row + index + 1 }}</span>
                </template>
                <template #video_count="{text, record, index}">
                    <a-row class="flex flex-ai-c flex-jc-sb" style="width:100%">
                        <span>{{text}}</span>
                        <icon-font type="icon-chakanliebiao" style="font-size:24px" @click="JumpBrowseVideo(record)" />
                    </a-row>
                </template>
                <template #operation="{ record }">
                    <a-button type="primary" @click="handleEdit(record)">编辑</a-button>
                </template>
            </a-table>
            <div class="flex flex-jc-fe Pageing">
                <TablePagination :curr="param.curr" :row="param.row" :count="count" @changeTableRequest="changeTableRequest" />
            </div>
        </a-row>

        <!--添加学生-->
        <StudyEdit ref="StudyEdit" @success="getList"></StudyEdit>
    </div>
</template>
<script>
import { defineComponent, reactive, toRefs, ref, getCurrentInstance, onMounted } from 'vue';
import TablePagination from '@/components/TablePagination';
import StudyEdit from '@/components/StudyEdit';
import useDelete from '@/hooks/useDelete.js';

export default defineComponent({
    components: {
        TablePagination,
        StudyEdit,
    },
    setup() {
        const { proxy } = getCurrentInstance();
        const { useDeleteFun } = useDelete();
        const StudyEdit = ref();
        onMounted(() => {
            getList();//学生列表
        });
        const initData = reactive({
            seletArray: {},//下拉列表
            list: [],//学生列表
            columns: [
                { title: 'ID', dataIndex: 'no'},
                { title: '姓名', dataIndex: 'realname' },
                { title: '电话', dataIndex: 'mobile' },
                { title: '学校', dataIndex: 'school' },
                { title: '年级', dataIndex: 'grade.name' },
                { title: '学期', dataIndex: 'term.name' },
                { title: '注册时间', dataIndex: 'create_time' },
                { title: '最近登录时间', dataIndex: 'login_last_time' },
                { title: '浏览视频', dataIndex: 'video_view_count', slots: { customRender: 'video_count' } },
                { title: '操作', fixed: 'right', width: 100, slots: { customRender: 'operation' } },
            ],

            param: {
                curr: 1,
                row: 10
            },
            count: 0,
            pages: 1
        });

        const getList = () => {
            proxy.http.post('/admin.student/index', initData.param).then(response => {
                initData.list = response.list;
                initData.count = response.count;
                initData.pages = response.pages;
            })
        };

        const filterList = () => {
            Object.assign(initData.param, { curr: 1 });
            Object.assign(initData, { list: [] });
            getList();
        }

        //分页change
        const changeTableRequest = (event, value) => {
            if (event == 'curr') {
                initData.param.curr = value;
            } else {
                initData.param.curr = 1;
                initData.param.row = value;
            }
            getList();
        };


        //新建弹框
        const handleEdit = (row) => {
            let { id, realname, mobile, school, grade_id, subject_id, term_id } = row;
            StudyEdit.value.form = {
                id,
                realname,
                mobile,
                school,
                grade_id,
                subject_id,
                term_id
            };
            StudyEdit.value.handleDrawer(true);
        };

        //删除
        const handleDelete = (row) => {
            useDeleteFun('/admin.knowledge/delete', [row.id]).then(() => {
                getList();
            })
        }

        const JumpBrowseVideo = (row) => {
            proxy.http.go('/StudentBrowseVideo', { id: row.id, name: row.realname })
        }

        return {
            ...toRefs(initData),
            getList,
            StudyEdit,
            changeTableRequest,
            handleEdit,
            handleDelete,
            filterList,
            JumpBrowseVideo
        }
    }
})
</script>
